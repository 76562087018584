import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import LabeledInputWithErrors from './Form/LabeledInputWithErrors';
import { useDebounceSelector } from './hooks/useDebounceSelector';
import { useFormHandlers } from './hooks/useFormHandlers';

import Button from 'components/Shared/Button/Button';
import {
  frictionlessCheckoutEmailSelector,
  frictionlessCheckoutPhoneSelector,
  isFrictionlessCheckoutEmailValidSelector,
  isFrictionlessCheckoutPhoneValidSelector,
} from 'store/auth/selectors';
import { authActions } from 'store/auth/slice';
import { useSelector } from 'store/utils';

const FrictionlessDetailsUpdate: React.FC = () => {
  const dispatch = useDispatch();
  const frictionlessCheckoutEmail = useSelector(frictionlessCheckoutEmailSelector);
  const frictionlessCheckoutPhone = useSelector(frictionlessCheckoutPhoneSelector);
  const isEmailValid = useDebounceSelector(isFrictionlessCheckoutEmailValidSelector);
  const isPhoneValid = useDebounceSelector(isFrictionlessCheckoutPhoneValidSelector);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  const { blurredFields, handleBlur, handleChange } = useFormHandlers();

  useEffect(() => {
    if (!isEmailValid) {
      setShowEmail(true);
    }
    if (!isPhoneValid) {
      setShowPhone(true);
    }
  }, []);

  const formIsValid = isEmailValid && isPhoneValid;

  const handleSubmit = () => {
    if (formIsValid) {
      dispatch(
        authActions.setFrictionlessCheckoutDetailsRequired({
          isDetailsRequired: false,
        })
      );
    }
  };

  return (
    <Fragment>
      <div className="frictionless-details-update">
        <div className="container">
          {showEmail && (
            <div>
              <LabeledInputWithErrors
                label={<FormattedMessage id="FRICTIONLESS_EMAIL" defaultMessage="Email" />}
                type="email"
                name="email"
                value={frictionlessCheckoutEmail || ''}
                error={blurredFields.email && !isEmailValid ? 'Invalid email' : ''}
                onChange={(e) => handleChange(e.target.value, 'email')}
                onBlur={() => handleBlur('email')}
                placeholder="Enter your email address"
              />
            </div>
          )}
          {showPhone && (
            <div>
              <LabeledInputWithErrors
                label={<FormattedMessage id="FRICTIONLESS_PHONE" defaultMessage="Phone number" />}
                type="tel"
                name="phone"
                value={frictionlessCheckoutPhone || ''}
                error={blurredFields.phone && !isPhoneValid ? 'Invalid phone number' : ''}
                onChange={(e) => handleChange(e.target.value, 'phone')}
                onBlur={() => handleBlur('phone')}
                placeholder="+1 (555) 555-5555"
              />
            </div>
          )}
        </div>
        <div className="submit-btn">
          <Button onClick={handleSubmit} buttonType="primary" disabled={!formIsValid} width="100%">
            <FormattedMessage
              id="FRICTIONLESS_DETAILS_UPDATE_BUTTON"
              defaultMessage="Update my details"
            />
          </Button>
        </div>
      </div>
      {/*language=SCSS*/}
      <style jsx>{`
        .frictionless-details-update {
          .container {
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .submit-btn {
            margin-top: 10px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export { FrictionlessDetailsUpdate };
