import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';

import type { FeatureFlags } from 'types/FeatureFlags';

import { concatPaymentMethodWithSubType } from 'businessLogic/Payment/helpers';
import PaymentMethodBugs from 'components/Core/Payment/PaymentMethod/PaymentMethodBugs/PaymentMethodBugs';
import PaymentMethodTab from 'components/Core/Payment/PaymentMethod/PaymentMethodTab/PaymentMethodTab';
import Card from 'components/Shared/Card/Card';
import BankTransfer from 'components/Shared/Icons/BankTransfer';
import {
  PayPal,
  CreditCard,
  DebitCard,
  Chevron,
  Apple,
  PaypalPPAAM,
  Venmo,
  PaypalPPAAMPayLater,
} from 'components/Shared/Icons/Icons';
import SegmentIO from 'reporting/SegmentIO';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { paymentMethodTypeChange } from 'store/payment/slice';
import { breakpoints, colors, fontSize } from 'styles/cp';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'sections/MobilePaymentMethodTabs';
import { IXP } from 'types/IXP';
import { Wallet } from 'types/Wallet';
import { PaymentMethod, PaymentMethodSubType, TXN_MAP, TXN_STATUSES } from 'types/constants';

type MobilePaymentMethodTabsProps = {
  allowedPaymentMethods: any[];

  enabledCCTypes: any[];
  paymentMethodTypeChange: typeof paymentMethodTypeChange;
  changeSelectedWallet: (type: 'AddCard' | 'AddBank') => any;
  insight: object;
  payMessageType?: React.ReactNode;
  shouldUseNewWalletFlow: boolean;
  hideModal: () => void;
  isUserSignedIn: boolean;
  featureFlags: FeatureFlags;

  cdn: string;
  payment: {
    paymentStatus: TXN_STATUSES;
    paymentMethodType: PaymentMethod;
    balanceAmount: number;
    paymentMethodSubType: PaymentMethodSubType;
  };
  ixp: IXP;
  wallet: Wallet;
  isPPCheckoutExperiment: boolean;
};

class MobilePaymentMethodTabs extends Component<MobilePaymentMethodTabsProps, {}> {
  constructor(props: MobilePaymentMethodTabsProps) {
    super(props);
  }
  componentDidMount() {
    const {
      allowedPaymentMethods,
      payment: { balanceAmount },
    } = this.props;
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'view',
      action: 'render',
      activityInfo: {
        activityObject: 'MobilePaymentMethodTabs',
        allowedPaymentMethods,
        balanceAmount,
        numberOfTabs: allowedPaymentMethods?.length,
      },
    });
  }
  scrollToForm() {
    window.scrollTo({ behavior: 'smooth', top: 520 });
  }

  onPaymentMethodTypeChange = (type: PaymentMethod, subType?: PaymentMethodSubType) => {
    const concatedType = concatPaymentMethodWithSubType(type, subType);
    const activity_type = `pm_${concatedType}`;
    SegmentIO.transactionEngaged({
      activity_type,
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: activity_type,
      ui_access_point: 'transaction_flow',
    });
    const { paymentStatus } = this.props.payment;
    if (paymentStatus !== TXN_MAP.STATUS.IN_PROGRESS) {
      this.props.paymentMethodTypeChange({
        paymentMethodType: type,
        paymentMethodSubType: subType,
      });
      if (this.props.shouldUseNewWalletFlow) {
        this.props.changeSelectedWallet(type === 'bank' ? 'AddBank' : 'AddCard');
      }
    }
    setTimeout(this.props.hideModal, 200);
    if (
      (!this.props.isUserSignedIn && type === 'bank') ||
      type === 'dc' ||
      type === 'cc' ||
      type === 'dc,cc'
    ) {
      this.scrollToForm();
    }
  };

  render() {
    const {
      allowedPaymentMethods,
      payment: { paymentMethodType, paymentMethodSubType: selectedPaymentMethodSubType },
      cdn,
      enabledCCTypes,
      wallet,
      isPPCheckoutExperiment,
    } = this.props;
    const headerLabel =
      paymentMethodType === null ? (
        <FormattedMessage
          id="PAYFORM_SELECT_PAYMENT_METHOD"
          defaultMessage="Select payment method"
        />
      ) : (
        <>
          {isPPCheckoutExperiment ? null : (
            <FormattedMessage id="CHANGE_PAYMENT_METHOD" defaultMessage="Change payment method" />
          )}
        </>
      );
    const numberOfTabs = allowedPaymentMethods.length;
    const shouldShowTwoTabs = numberOfTabs === 2 && !allowedPaymentMethods.includes('ap');
    const getPaymentMethodTab = ({
      allowedPaymentMethod,
      icon,
      formattedMessageId,
      defaultMessage,
      paymentMethodSubType,
      wallet,
    }: {
      allowedPaymentMethod: PaymentMethod;
      icon: Function;
      formattedMessageId: string;
      defaultMessage: string;
      paymentMethodSubType?: PaymentMethodSubType;
      wallet?: Wallet;
    }) => (
      <PaymentMethodTab
        isMobile={true}
        key={concatPaymentMethodWithSubType(allowedPaymentMethod, paymentMethodSubType)}
        onPaymentMethodTypeChange={this.onPaymentMethodTypeChange}
        Icon={icon}
        paymentMethod={allowedPaymentMethod}
        paymentMethodSubType={paymentMethodSubType}
        selectedPaymentMethod={concatPaymentMethodWithSubType(
          paymentMethodType,
          selectedPaymentMethodSubType
        )}
        formattedMessageId={formattedMessageId}
        defaultMessage={defaultMessage}
        availablePayments={allowedPaymentMethods}
        wallet={wallet}
        isPPCheckoutExperiment={isPPCheckoutExperiment}
      />
    );

    // @ts-ignore
    const paymentMethodsTabs = allowedPaymentMethods.map((allowedPaymentMethod) => {
      switch (allowedPaymentMethod) {
        case 'ap':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: Apple,
            formattedMessageId: 'PAYFLOW_PAYMENT_TYPE_APPLE_PAY',
            defaultMessage: 'Apple Pay',
          });
        case 'dc':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: DebitCard,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_DEBIT',
            defaultMessage: 'Debit',
          });
        case 'dc,cc':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: DebitCard,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_CREDIT_DEBIT',
            defaultMessage: 'Credit/Debit',
          });
        case 'bank':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK',
            defaultMessage: 'Bank',
          });
        case 'pbb':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK',
            defaultMessage: 'Bank',
          });
        case 'nanopay':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK_CA',
            defaultMessage: 'Bank payments',
          });
        case 'eft':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK_CA',
            defaultMessage: 'Bank payments',
          });
        case 'cc':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: CreditCard,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_CREDIT',
            defaultMessage: 'Credit card',
          });
        case 'paypal':
          return getPaymentMethodTab({
            allowedPaymentMethod: 'pp',
            icon: PayPal,
            formattedMessageId: 'PAYFLOW_PAYMENT_TYPE_PAYPAL',
            defaultMessage: 'PayPal',
          });
        case 'paypal_ppaam':
          if (isPPCheckoutExperiment) {
            return;
          } else {
            return getPaymentMethodTab({
              allowedPaymentMethod,
              icon: PaypalPPAAM,
              formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_PAYPAL',
              defaultMessage: 'PayPal',
            });
          }
        case 'paypal_ppaam paylater':
          return getPaymentMethodTab({
            allowedPaymentMethod: 'paypal_ppaam',
            icon: PaypalPPAAMPayLater,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_PAYPAL_PAYLATER',
            defaultMessage: 'Pay Later',
            paymentMethodSubType: 'paylater',
            wallet,
          });
        case 'venmo':
          if (isPPCheckoutExperiment) {
            return;
          } else {
            return getPaymentMethodTab({
              allowedPaymentMethod,
              icon: Venmo,
              formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_VENMO',
              defaultMessage: 'Venmo',
            });
          }
      }
    });
    return (
      <Fragment>
        <div className="mobile-header">{headerLabel}</div>
        {<ul>{paymentMethodsTabs}</ul>}
        {!paymentMethodType && (
          <div className="bugs-wrapper">
            <PaymentMethodBugs
              enabledPaymentMethods={allowedPaymentMethods}
              enabledCCTypes={enabledCCTypes}
              cdn={cdn}
              ccBugsOnly={false}
            />
          </div>
        )}
        <style jsx>{`
          .mobile-header {
            font-family: AvenirNextforINTUIT-Demi;
            font-size: ${fontSize.xxs};
            color: ${colors.whiteGray};
            text-transform: uppercase;
            display: block;
            //margin: 8px 10px;
          }

          ul {
            list-style: none;
            width: 100%;
            padding: ${this.props.isUserSignedIn ? '6px 0' : '6px 0'};
            margin: 14px 0;
            display: grid;
            grid-template-columns: ${shouldShowTwoTabs ? '1fr 1fr' : '1fr 1fr 1fr'};
            column-gap: 8px;
            row-gap: 8px;
            @media screen and (max-width: ${breakpoints.xs}) {
              grid-template-columns: 1fr 1fr;
            }
          }

          .bugs-wrapper {
            padding: 15px 0;
          }
        `}</style>
      </Fragment>
    );
  }
}

type MobilePaymentMethodTabsWrapperProps = {
  allowedPaymentMethods: PaymentMethod[];
  showModal: ({
    component,
    componentProps,
  }: {
    component: any;
    componentProps: { closeOnEsc: boolean };
  }) => void;
  enabledCCTypes: any[];
  hideModal: () => void;
  isUserSignedIn: boolean;
  payment: {
    paymentStatus: TXN_STATUSES;
    paymentMethodType: PaymentMethod;
    balanceAmount: number;
    paymentMethodSubType: PaymentMethodSubType;
  };
  insight: object;
  featureFlags: FeatureFlags;
  ixp: IXP;
  paymentMethodTypeChange: typeof paymentMethodTypeChange;
  shouldUseNewWalletFlow: boolean;
  wallet: Wallet;

  cdn: string;
  changeSelectedWallet: (type: 'AddCard' | 'AddBank') => any;
  isPPCheckoutExperiment: boolean;
  isOnlyPaypalORVenmo: boolean;
};

class MobilePaymentMethodTabsWrapper extends Component<MobilePaymentMethodTabsWrapperProps, {}> {
  render() {
    const { allowedPaymentMethods, isOnlyPaypalORVenmo } = this.props;
    const showMobileGuestLayout = () => {
      return <MobilePaymentMethodTabs {...this.props} />;
    };
    const showMobileSingedInLayout = () => {
      return (
        <Fragment>
          <div className="pm-type" onClick={openModal}>
            <FormattedMessage id="CHANGE_PAYMENT_METHOD" defaultMessage="Change payment method" />
            <Chevron width={10} height={10} color={colors.black} />
          </div>
          <style jsx>{`
            .pm-type {
              height: 47px;
              margin: 10px 0 20px 0;
              padding: 14px 10px;
              font-size: ${fontSize.xs};
              color: ${colors.lightGray};
              background-color: ${colors.gray09};
              font-family: AvenirNextforINTUIT-Medium;

              :global(svg) {
                transform: rotate(90deg);
                float: right;
                margin-top: 5px;
              }
            }
          `}</style>
        </Fragment>
      );
    };
    const openModal = () => {
      this.props.showModal({
        component: () => {
          return (
            <Fragment>
              <Card>
                <span className="wallet-card-wrapper">
                  <span className="close" onClick={this.props.hideModal}>
                    X
                  </span>

                  <MobilePaymentMethodTabs {...this.props} />
                </span>
              </Card>

              <style jsx>{`
                .wallet-card-wrapper {
                  display: flex;
                  position: relative;
                  flex-direction: column;
                  padding: 24px;
                }
                .modal-header {
                  padding: 20px 10px 20px 10px;
                  font-family: AvenirNextforINTUIT-Demi;
                  color: ${colors.gray02};
                  font-size: ${fontSize.xxs};
                  text-transform: uppercase;
                }
                .close {
                  position: absolute;
                  right: 24px;
                  top: 12px;
                  color: ${colors.gray05};
                  font-size: ${fontSize.md};
                }
              `}</style>
            </Fragment>
          );
        },
        componentProps: {
          closeOnEsc: true,
        },
      });
    };

    if (
      allowedPaymentMethods &&
      Array.isArray(allowedPaymentMethods) &&
      allowedPaymentMethods.length < 2
    ) {
      return null;
    }
    return (
      <Fragment>
        {!isOnlyPaypalORVenmo
          ? this.props.isUserSignedIn
            ? showMobileSingedInLayout()
            : showMobileGuestLayout()
          : null}
      </Fragment>
    );
  }
}
export default MobilePaymentMethodTabsWrapper;
