export const loadAppFabricDevelopmentKit = (
  sdkSrc: string,
  onError: () => void,
  onLoad?: (ev: Event) => void
): void => {
  if (window.AppFabric) {
    return;
  }

  const script = document.createElement('script');
  script.src = sdkSrc;
  script.async = true;
  if (onLoad) {
    script.onload = onLoad;
  }
  script.onerror = onError;
  document.body.appendChild(script);
};

export const cleanupAppFabricDevelopmentKit = (sdkSrc: string) => {
  const script = document.querySelector(`script[src="${sdkSrc}"]`);
  if (script) {
    document.body.removeChild(script);
  }
};

type Props = {
  [key: string]: string | boolean | number | Props | ((...args: any[]) => void);
};

export const renderAppFabricWidget = async (
  sdkSrc: string,
  widgetId: string,
  container: HTMLDivElement,
  props: Props,
  onError: () => void = () => undefined
) => {
  // If the AppFabric SDK is not loaded yet, load it first
  if (!window.AppFabric) {
    try {
      await new Promise((resolve, reject) => {
        loadAppFabricDevelopmentKit(sdkSrc, reject, resolve);
      });
    } catch {
      onError();
      return;
    }
  }
  const instance = await window.AppFabric.init();
  await instance.renderWidget(widgetId, {
    version: '1.0.0',
    props,
    container: container,
  });
};
