import React, { useState, ReactNode } from 'react';

import CpPopover from 'components/Shared/Popover/CpPopover';

type TooltipProps = {
  children: ReactNode;
  title: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  tooltipClass?: string;
  tooltipId: string;
};

const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  placement = 'bottom',
  tooltipClass,
  tooltipId,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleMouseEnter = () => setIsTooltipOpen(true);
  const handleMouseLeave = () => setIsTooltipOpen(false);

  return (
    <React.Fragment>
      <span
        id={tooltipId}
        onTouchStart={handleMouseEnter}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={tooltipClass}
      >
        {children}
      </span>
      {isTooltipOpen && (
        <CpPopover
          innerClassName="cp-tooltip"
          placement={placement}
          target={tooltipId}
          isOpen={isTooltipOpen}
        >
          <span>{title}</span>
        </CpPopover>
      )}
    </React.Fragment>
  );
};

export default Tooltip;
