import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FrictionlessDetailsRequired } from './FrictionlessDetailsRequired';
import { FrictionlessDisclaimer } from './FrictionlessDisclaimer';

import { Mobile } from 'components/Shared/Icons/Icons';
import Switch from 'components/Shared/Inputs/Switch';
import {
  isFrictionlessCheckoutDetailsRequiredSelector,
  isFrictionlessCheckoutSelectedSelector,
} from 'store/auth/selectors';
import { authActions } from 'store/auth/slice';
import { useSelector } from 'store/utils';
import { colors, fontSize } from 'styles/cp';

const FrictionlessCheckout: React.FC = () => {
  const dispatch = useDispatch();
  const isFrictionlessCheckoutSelected = useSelector(isFrictionlessCheckoutSelectedSelector);
  const isFrictionlessCheckoutDetailsRequired = useSelector(
    isFrictionlessCheckoutDetailsRequiredSelector
  );

  const handleChange = () => {
    dispatch(
      authActions.setFrictionlessCheckoutSelected({
        frictionlessCheckoutSelected: !isFrictionlessCheckoutSelected,
      })
    );
  };

  const handleHide = () => {
    dispatch(
      authActions.setFrictionlessCheckoutSelected({
        frictionlessCheckoutSelected: false,
      })
    );
    dispatch(
      authActions.setFrictionlessCheckoutDetailsRequired({
        isDetailsRequired: false,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="frictionless-checkout">
        <div className="frictionless-checkout--header">
          <FormattedMessage
            id="FRICTIONLESS_SAVE_INFO"
            defaultMessage="Save my info for a faster checkout"
          />
          <div>
            <Switch checked={isFrictionlessCheckoutSelected} onChange={handleChange} />
          </div>
        </div>
        <div className="frictionless-checkout--content">
          <ul>
            <li>
              <FormattedMessage
                id="FRICTIONLESS_SAVE_INFO_BENEFIT_1"
                defaultMessage="1-click, passwordless checkout"
              />
            </li>
            <li>
              <FormattedMessage
                id="FRICTIONLESS_SAVE_INFO_BENEFIT_2"
                defaultMessage="Save payment info with an Intuit Account"
              />
            </li>
            <li>
              <FormattedMessage
                id="FRICTIONLESS_SAVE_INFO_BENEFIT_3"
                defaultMessage="Pay any merchant in the Intuit network"
              />
            </li>
          </ul>
        </div>
        {isFrictionlessCheckoutSelected && (
          <div className="frictionless-checkout--footer">
            <div className="mobile-icon">
              <Mobile width={14} height={20} color={colors.pepper50} />
            </div>
            <FormattedMessage
              id="FRICTIONLESS_PHONE_VALIDATION_TEXT"
              defaultMessage="After selecting “Pay”, we’ll send a code to your phone to quickly verify it’s you and complete your account. Standard call or SMS rates may apply."
            />
          </div>
        )}
      </div>
      {isFrictionlessCheckoutSelected && <FrictionlessDisclaimer />}
      {isFrictionlessCheckoutDetailsRequired && <FrictionlessDetailsRequired onHide={handleHide} />}
      {/*language=SCSS*/}
      <style jsx>{`
        .frictionless-checkout {
          margin: 20px 0;
          border: 1px solid ${colors.gray05};
          border-radius: 8px;
          overflow: hidden;

          &--header {
            padding: 10px 14px;
            display: flex;
            justify-content: space-between;
            font-family: AvenirNextforINTUIT-Demi;
            font-size: ${fontSize.xs};
            border-bottom: 1px solid ${colors.gray05};
          }
          &--content {
            font-size: ${fontSize.xs};
            padding: 10px 14px;
            ul {
              padding: 0;
              margin: 6px 18px 0 18px;
              li {
                margin-bottom: 6px;
              }
            }
          }
          &--footer {
            display: flex;
            background: ${colors.stoneWhite};
            font-size: ${fontSize.xxxs};
            color: ${colors.gray02};
            padding: 10px 14px;
            .mobile-icon {
              margin: 5px 20px 0 0;
            }
          }
        }
        .disclaimer {
          font-size: ${fontSize.xxs};
          color: ${colors.gray02};
          margin: 25px 0 15px 0;
          text-align: center;
          a {
            color: ${colors.blue};
            text-decoration: underline;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { FrictionlessCheckout };
