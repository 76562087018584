import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const accountNumberCAValidator: WalletValidatorFunction = (
  accountNumber: string
): [boolean, ValidationError[]] => {
  const errors: ValidationError[] = [];

  if (typeof accountNumber !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    // valid bank account number in Canada should have betweeen 7 and 17 digits
    const accountNumberRegEx = /^\d{7,12}$/;
    const maskedValueRegEx = /^[x]*\d{7}$/;
    const result = accountNumberRegEx.test(accountNumber) || maskedValueRegEx.test(accountNumber);
    if (!result) {
      errors.push(ValidationErrorEnum.INVALID_ACCOUNT_NUMBER_CA);
    }
  }

  return [errors.length === 0, errors];
};
