import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export const CheckoutPortalFormattedMessage: React.FC<CheckoutPortalFormattedMessageProps> = (
  props
): ReactElement => {
  // @ts-expect-error
  // FormattedMessage doesn't officially support the values as type (chunks: string) => React.JSX.Element)
  return <FormattedMessage {...props} />;
};

type MessageValue = string | number | boolean | Date | null | undefined;

interface CheckoutPortalFormattedMessageProps {
  values?: { [key: string]: MessageValue | JSX.Element | ((chunks: string) => React.JSX.Element) };
  tagName?: string;
  children?: (...formattedMessage: Array<string | JSX.Element>) => React.ReactNode;
  id: string;
  defaultMessage?: string;
}
