import React from 'react';

import { colors } from 'styles/cp';

export interface DropdownOptionType {
  name: string;
  issuerId: string;
  logoUrl?: string;
}
export interface DropdownOptionProps {
  option: DropdownOptionType;
  onClick?: () => void;
  isMobile?: boolean;
  key?: string;
}

const DropdownOption = (props: DropdownOptionProps) => {
  const { option, onClick, isMobile, key } = props;

  return (
    <>
      <li className="dropdown-option" onClick={onClick} key={key}>
        {option?.logoUrl && !isMobile && <img className="dropdown-image" src={option?.logoUrl} />}
        <span className="name">{option?.name}</span>
      </li>
      <style jsx>
        {`
          .dropdown-option {
            display: flex;
            color: ${colors.gray01};
            cursor: pointer;
            padding: 5px 8px;
            :hover {
              border: solid 1px ${colors.green};
            }

            .dropdown-image {
              max-height: 43px;
              max-width: 43px;
              margin-right: 10px;
            }
          }
        `}
      </style>
    </>
  );
};

export default DropdownOption;
