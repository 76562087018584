import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dispatch } from 'redux';

import { LocalStorageUtil } from 'businessLogic/utils/storage';
import ScrollingForm, {
  InjectedScrollingFormProps,
} from 'components/Core/Payment/PaymentForm/ScrollingForm/ScrollingForm';
import Dropdown from 'components/Shared/Dropdown/Dropdown';
import { DropdownOptionType } from 'components/Shared/Dropdown/DropdownOption/DropdownOption';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import SegmentIO from 'reporting/SegmentIO';
import { setSelectedBank } from 'store/payment/slice';
import { connect } from 'store/utils';
import { fontSize } from 'styles/cp';
import { SelectedBank } from 'types/PayByBank';
import { TXN_MAP } from 'types/constants';

type PayByBankFormProps = {
  bankList: Array<any>;
  setSelectedBank: typeof setSelectedBank;
  paymentStatus: string;
} & InjectedScrollingFormProps;

const PayByBankForm: React.FC<PayByBankFormProps> = ({
  bankList,
  paymentStatus,
  setSelectedBank,
}) => {
  const [selectedBankValueFromStorage, setSelectedBankValueFromStorage] = useState<string>();
  const handleSelectedOption = (option: DropdownOptionType) => {
    setSelectedBank({ bankName: option?.name, bankId: option?.issuerId });
    SegmentIO.transactionEngaged({
      scope: 'counterpart_portal',
      scope_area: 'checkout_&_shopping_cart',
      ui_action: 'clicked',
      ui_object: 'dropdown',
      ui_object_detail: `payor_bank:${option?.name}`,
      ui_access_point: 'transaction_flow',
    });
    LocalStorageUtil.setToStorage('lastSelectedBank', option);
  };
  useEffect(() => {
    const selectedBankFromStorage: DropdownOptionType | null =
      LocalStorageUtil.getFromStorage('lastSelectedBank');
    if (selectedBankFromStorage) {
      setSelectedBankValueFromStorage(selectedBankFromStorage?.name);
      setSelectedBank({
        bankName: selectedBankFromStorage?.name,
        bankId: selectedBankFromStorage?.issuerId,
      });
    }
    if (paymentStatus === TXN_MAP.STATUS.PBB_STATUSES.PENDING) {
      setTimeout(() => {
        window.location.reload();
      }, 60000);
    }
  }, []);

  const isMobile = useIsMobileScreen();

  return (
    <React.Fragment>
      <div className="pbb-form">
        <div className="pbb-form-text">
          <div className="pbb-form-header">
            <FormattedMessage id="PBB_HEADER" defaultMessage="Choose a bank account" />
          </div>
          <div className="pbb-form-body">
            <FormattedMessage
              id="PBB_BODY"
              defaultMessage="Bank payments are simple, safe, and secure with QuickBooks."
            />
          </div>
        </div>
        <div className="pbb-dropdown">
          <Dropdown
            placeholder={
              selectedBankValueFromStorage ? selectedBankValueFromStorage : 'Select your bank'
            }
            onSelectOption={handleSelectedOption}
            options={bankList}
            isMobile={isMobile}
          />
        </div>
      </div>
      <style jsx>{`
        .pbb-form-text {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .pbb-form-header {
            font-size: ${fontSize.md2};
            font-weight: 600;
            line-height: 28px;
          }

          .pbb-form-body {
            font-size: ${fontSize.sm};
            font-weight: 400;
            line-height: 24px;
          }
        }
        .pbb-dropdown {
          padding-top: 30px;
        }
      `}</style>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setSelectedBank: (selectedBank: SelectedBank) => dispatch(setSelectedBank(selectedBank)),
});

export default connect(() => ({}), mapDispatchToProps)(ScrollingForm(PayByBankForm));
