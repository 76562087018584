import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getLegalLinks } from 'shared/clientUtils';
import { colors, fontSize } from 'styles/cp';
const { intuitAccountUrl, termsUrl, privacyStatementUrl } = getLegalLinks();

const FrictionlessDisclaimer: React.FC = () => {
  return (
    <React.Fragment>
      <div className="frictionless-disclaimer">
        <FormattedMessage
          id="FRICTIONLESS_DISCLAIMER"
          defaultMessage={`By continuing and creating your <link1>Intuit Account</link1>, you agree to our <link2>Terms</link2> and acknowledge our <link3>Privacy Statement</link3>.`}
          values={{
            // @ts-ignore
            link1: (chunks: string) => (
              <a href={intuitAccountUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
            // @ts-ignore
            link2: (chunks: string) => (
              <a href={termsUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
            // @ts-ignore
            link3: (chunks: string) => (
              <a href={privacyStatementUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .frictionless-disclaimer {
          font-size: ${fontSize.xxs};
          color: ${colors.gray02};
          margin: 25px 0 15px 0;
          text-align: center;
          a {
            color: ${colors.blue};
            text-decoration: underline;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { FrictionlessDisclaimer };
