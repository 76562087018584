import cn from 'classnames';
import React from 'react';

import { borderRadius, colors, paddingSize } from 'styles/cp';

export interface PaperProps {
  children: React.ReactNode;
  action?: React.ReactNode;
}

const Paper: React.FC<PaperProps> = ({ children, action }) => {
  return (
    <>
      <div className={cn('wrapper')}>
        <div className={cn('left')}>{children}</div>
        {action && <div className={cn('action')}>{action}</div>}
      </div>
      <style jsx>
        {`
          .wrapper {
            background-color: ${colors.gray09};
            border-radius: ${borderRadius.default};
            display: inline-block;
            padding: ${paddingSize.xs} ${paddingSize.xs} ${paddingSize.xs} ${paddingSize.md};
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
          }
          .left {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `}
      </style>
    </>
  );
};

export default Paper;
