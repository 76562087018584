import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import EftInfoModal from './EftInfoModal/EftInfoModal';

import ScrollingForm, {
  InjectedScrollingFormProps,
} from 'components/Core/Payment/PaymentForm/ScrollingForm/ScrollingForm';
import { IBankAccountCreateData } from 'components/Core/WalletForms/network/src/types';
import { BankField, FieldName } from 'components/Core/WalletForms/src/CreateBankTokenForm';
import { EnhancedFormikProps } from 'components/Core/WalletForms/src/Form';
import { BankAccountTypeEnumInput } from 'components/Core/WalletForms/validations/src/types';
import Caret from 'components/Shared/Icons/Caret';
import LabeledSelect from 'components/Shared/Inputs/LabeledSelect';
import ValidatedLabeledInput from 'components/Shared/Inputs/ValidatedLabeledInput';
import { getLegalLinks } from 'shared/clientUtils';
import { formErrorTranslator } from 'store/wallet/errorTranslators';
import { colors, breakpoints, fontSize } from 'styles/cp';
import { ShowModalFunc, HideModalFunc } from 'types/Modal';

type EFTFormProps = {
  bankDraftEnumErrors: EnhancedFormikProps<IBankAccountCreateData>['enumErrors'];
  bankDraftTouched: EnhancedFormikProps<IBankAccountCreateData>['touched'];
  onFieldBlur: (_field: {
    name: string;
    onBlur: Function;
  }) => (e: React.FocusEvent<HTMLInputElement>) => void;
  onBankFieldChange: (
    _field: {
      name: string;
      onChange: Function;
      value: string;
    },
    restProps?: {
      handleCheckboxOnChange?: Function;
      isChecked?: boolean;
    }
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBankFieldFocus: (
    _field: { name: string; onFocus?: Function },
    fieldRef: React.RefObject<HTMLElement>
  ) => (e: React.FocusEvent<HTMLInputElement>) => void;
  isSubmitting: boolean;
  showModal: ShowModalFunc;
  hideModal: HideModalFunc;
} & InjectedScrollingFormProps;

export const EFTForm: React.FC<EFTFormProps> = ({
  bankDraftTouched,
  bankDraftEnumErrors,
  scrollToRef,
  onFieldBlur,
  onBankFieldChange,
  onBankFieldFocus,
  isSubmitting,
  showModal,
  hideModal,
}) => {
  const accountInfoRef = useRef(null);
  const nameRef = useRef(null);
  const zipRef = useRef(null);
  const { formatMessage, ...intl } = useIntl();
  const { padUrl } = getLegalLinks(intl?.locale);

  const errorTranslator = formErrorTranslator();
  const [isChecked, setIsChecked] = useState(false);

  const scrollIfInError = ({ name }: { name: string }) => {
    const isInError =
      bankDraftTouched[name as FieldName] &&
      bankDraftEnumErrors[name as FieldName] &&
      typeof bankDraftEnumErrors[name as FieldName]![0] !== 'undefined';
    if (!isInError) {
      return null;
    }
    let refName: React.RefObject<HTMLElement>;
    switch (name) {
      case 'accountNumber':
      case 'transitNumber':
      case 'institutionNumber':
        refName = accountInfoRef;
        break;
      case 'name':
        refName = nameRef;
        break;
      case 'zipCode':
        refName = zipRef;
        break;
      default:
        refName = React.createRef<HTMLElement>();
    }
    return scrollToRef && refName && scrollToRef(refName, false);
  };
  const hideCancelModal = () => {
    hideModal();
  };

  const handleCheckboxOnChange = () => {
    setIsChecked(!isChecked);
  };

  const openModal = () => {
    const ModalContent = () => <EftInfoModal onContinue={hideCancelModal} hide={hideCancelModal} />;
    showModal({
      component: ModalContent,
      componentProps: {
        closeOnClickOutSide: false,
        closeOnEsc: false,
        showCloseButton: false,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="info-modal">
        <a href={'#'} onClick={openModal}>
          <FormattedMessage id="EFT_INFO_MODAL_LINK" defaultMessage="Where can I find this info" />
        </a>
        <Caret />
      </div>
      <div ref={accountInfoRef} className="flex an-w">
        <BankField name="transitNumber">
          {({ field }) => (
            <div className="bank-field">
              <>
                <ValidatedLabeledInput
                  {...field}
                  translator={errorTranslator}
                  disabled={isSubmitting}
                  inputMode="decimal"
                  pattern="[0-9]*"
                  type="text"
                  maxLength="5"
                  dir="ltr"
                  onBlur={onFieldBlur(field)}
                  onChange={onBankFieldChange(field)}
                  onFocus={onBankFieldFocus(field, accountInfoRef)}
                  className="flex-any"
                  label={
                    <FormattedMessage
                      id="EFT_PAYFLOW_TRANSIT_NUMBER"
                      defaultMessage="Transit number"
                    />
                  }
                  placeholder={formatMessage({
                    id: 'EFT_PAYFLOW_TRANSIT_NUMBER_PLACEHOLDER',
                    defaultMessage: '5 digits',
                  })}
                  validationError={
                    bankDraftTouched[field.name as 'transitNumber'] &&
                    bankDraftEnumErrors[field.name as 'transitNumber'] &&
                    bankDraftEnumErrors[field.name as 'transitNumber']![0]
                  }
                ></ValidatedLabeledInput>
                {scrollIfInError(field)}
              </>
            </div>
          )}
        </BankField>
        <BankField name="institutionNumber">
          {({ field }) => (
            <div className="bank-field">
              <ValidatedLabeledInput
                {...field}
                translator={errorTranslator}
                disabled={isSubmitting}
                inputMode="decimal"
                pattern="[0-9]*"
                type="text"
                maxLength="3"
                dir="ltr"
                onBlur={onFieldBlur(field)}
                onChange={onBankFieldChange(field)}
                onFocus={onBankFieldFocus(field, accountInfoRef)}
                className="flex-any"
                onPaste={(e: React.ClipboardEvent) => {
                  e.preventDefault();
                }}
                autoComplete="off"
                label={
                  <FormattedMessage
                    id="EFT_PAYFLOW_INSTITUTION_NUMBER"
                    defaultMessage="Institution number"
                  />
                }
                placeholder={formatMessage({
                  id: 'EFT_PAYFLOW_INSTITUTION_NUMBER_PLACEHOLDER',
                  defaultMessage: '3 digits',
                })}
                validationError={
                  bankDraftTouched[field.name as 'institutionNumber'] &&
                  bankDraftEnumErrors[field.name as 'institutionNumber'] &&
                  bankDraftEnumErrors[field.name as 'institutionNumber']![0]
                }
              />
            </div>
          )}
        </BankField>
        <BankField name="accountNumber">
          {({ field }) => (
            <div className="bank-field">
              <>
                <ValidatedLabeledInput
                  {...field}
                  translator={errorTranslator}
                  disabled={isSubmitting}
                  inputMode="decimal"
                  pattern="[0-9]*"
                  type="text"
                  minLength="5"
                  maxLength="12"
                  dir="ltr"
                  onBlur={onFieldBlur(field)}
                  onChange={onBankFieldChange(field)}
                  onFocus={onBankFieldFocus(field, accountInfoRef)}
                  className="flex-any"
                  label={
                    <FormattedMessage
                      id="EFT_PAYFLOW_ACCOUNT_NUMBER"
                      defaultMessage="Account number"
                    />
                  }
                  placeholder={formatMessage({
                    id: 'EFT_PAYFLOW_ACCOUNT_NUMBER_PLACEHOLDER',
                    defaultMessage: '5-12 digits',
                  })}
                  validationError={
                    bankDraftTouched[field.name as 'accountNumber'] &&
                    bankDraftEnumErrors[field.name as 'accountNumber'] &&
                    bankDraftEnumErrors[field.name as 'accountNumber']![0]
                  }
                ></ValidatedLabeledInput>
                {scrollIfInError(field)}
              </>
            </div>
          )}
        </BankField>
      </div>
      <BankField name="accountType">
        {({ field }) => (
          <div className="bank-field">
            <LabeledSelect
              {...field}
              disabled={isSubmitting}
              onChange={onBankFieldChange(field)}
              label={
                <FormattedMessage id="PAYFLOW_BANK_ACCOUNT_TYPE" defaultMessage="Account type" />
              }
            >
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_PERSONAL_CHECKING"
                defaultMessage="Personal checking"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.PERSONAL_CHECKING}>{message}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_PERSONAL_SAVINGS"
                defaultMessage="Personal savings"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.PERSONAL_SAVINGS}>{message}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_BUSINESS_CHECKING"
                defaultMessage="Business Checking"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.BUSINESS_CHECKING}>{message}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_BUSINESS_SAVINGS"
                defaultMessage="Business savings"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.BUSINESS_SAVINGS}>{message}</option>
                )}
              </FormattedMessage>
            </LabeledSelect>
          </div>
        )}
      </BankField>

      <BankField name="name">
        {({ field }) => (
          <div ref={nameRef} className="bank-field">
            <>
              <ValidatedLabeledInput
                {...field}
                translator={errorTranslator}
                disabled={isSubmitting}
                onBlur={onFieldBlur(field)}
                onChange={onBankFieldChange(field)}
                onFocus={onBankFieldFocus(field, nameRef)}
                maxLength="64"
                label={
                  <FormattedMessage
                    id="EFT_PAYFLOW_BANK_ACCOUNT_NAME"
                    defaultMessage="Account holder's name"
                  />
                }
                validationError={
                  bankDraftTouched[field.name as 'name'] &&
                  bankDraftEnumErrors[field.name as 'name'] &&
                  bankDraftEnumErrors[field.name as 'name']![0]
                }
              />
              {scrollIfInError(field)}
            </>
          </div>
        )}
      </BankField>
      <BankField name="zipCode">
        {({ field }) => (
          <div ref={zipRef}>
            <>
              <ValidatedLabeledInput
                {...field}
                autoComplete={'postal-code'}
                translator={errorTranslator}
                validationError={
                  bankDraftTouched[field.name as 'zipCode'] &&
                  bankDraftEnumErrors[field.name as 'zipCode'] &&
                  bankDraftEnumErrors[field.name as 'zipCode']![0]
                }
                onFocus={onBankFieldFocus(field, zipRef)}
                onChange={onBankFieldChange(field)}
                onBlur={onFieldBlur(field)}
                disabled={isSubmitting}
                label={
                  <FormattedMessage id="EFT_PAYFLOW_POSTAL_CODE" defaultMessage="Postal code" />
                }
                placeholder={formatMessage({
                  id: 'EFT_PAYFLOW_POSTAL_CODE_PLACEHOLDER',
                  defaultMessage: 'L4W 5G1',
                })}
                type="text"
                maxLength="6"
                dir="ltr"
              />
              {scrollIfInError(field)}
            </>
          </div>
        )}
      </BankField>
      <BankField name="padTerms">
        {({ field }) => (
          <div className="bank-field pad">
            <>
              <ValidatedLabeledInput
                {...field}
                translator={errorTranslator}
                onChange={onBankFieldChange(field, { handleCheckboxOnChange, isChecked })}
                disabled={isSubmitting}
                type="checkbox"
                checked={isChecked}
                validationError={
                  bankDraftTouched[field.name as 'padTerms'] &&
                  bankDraftEnumErrors[field.name as 'padTerms'] &&
                  bankDraftEnumErrors[field.name as 'padTerms']![0]
                }
                label={
                  <span style={{ fontSize: fontSize.xxs }}>
                    <FormattedMessage
                      id="EFT_PAD_TERMS"
                      values={{
                        1: padUrl,
                        link1: (
                          <a className="cp-link" href={padUrl} target="_blank" rel="noreferrer">
                            PAD terms
                          </a>
                        ),
                      }}
                      defaultMessage="I have read and agree to the pre-authorized debit <link1>(PAD) terms</link1>."
                    />
                  </span>
                }
              />
              {scrollIfInError(field)}
            </>
          </div>
        )}
      </BankField>
      <style jsx>{`
        .bank-field {
          width: 100%;
          margin-bottom: 12px;

          .tooltip-target-wrapper {
            margin: 8px;
            height: 18px;
          }
        }

        .pad {
          padding-top: 12px;
        }

        .cp-link {
          color: ${colors.blue};
          text-decoration: underline;
        }

        .zip {
          flex: 1;
          padding-left: 8px;
          max-width: fit-content;

          @media screen and (max-width: ${breakpoints.md}) {
            flex: auto;
            max-width: 100%;
            padding: 0;
          }
        }

        .an-w {
          width: 100%;
          @media screen and (max-width: ${breakpoints.sm}) {
            display: block;
          }

          .bank-field {
            margin-right: 19px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        .info-modal {
          display: flex;
          padding-bottom: 15px;

          :hover {
            text-decoration: underline;
            color: ${colors.blue};
          }

          a {
            color: ${colors.blue};
            font-weight: 400;
            text-decoration: none;
            font-size: 14px;
            line-height: 20px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default ScrollingForm(EFTForm);
