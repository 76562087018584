import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FrictionlessDetailsUpdate } from './FrictionlessDetailsUpdate';

import BottomSheetModal from 'components/Core/Modal/BottomSheetModal';

interface FrictionlessDetailsProps {
  onHide: () => void;
}

const FrictionlessDetailsUpdateHeader = (
  <div style={{ fontSize: '20px' }}>
    <FormattedMessage id="FRICTIONLESS_DETAILS" defaultMessage="Your details" />
  </div>
);

const FrictionlessDetailsUpdateSubHeader = (
  <div style={{ width: '354px', textAlign: 'left', fontSize: '14px' }}>
    <FormattedMessage
      id="FRICTIONLESS_DETAILS_INFO"
      defaultMessage="This is needed to securely save your information and offer you a faster checkout next time."
    />
  </div>
);

export const FrictionlessDetailsRequired: React.FC<FrictionlessDetailsProps> = ({ onHide }) => {
  return (
    <BottomSheetModal
      hide={onHide}
      header={FrictionlessDetailsUpdateHeader}
      subHeader={FrictionlessDetailsUpdateSubHeader}
      closeIconPosition={{ right: '-10px', top: '-10px' }}
      padding="24px"
    >
      <FrictionlessDetailsUpdate />
    </BottomSheetModal>
  );
};
