import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import LabeledInputWithErrors from './Form/LabeledInputWithErrors';
import { useDebounceSelector } from './hooks/useDebounceSelector';
import { useFormHandlers } from './hooks/useFormHandlers';

import { InfoCircle } from 'components/Shared/Icons/Icons';
import Tooltip from 'components/Shared/Tooltip/Tooltip';
import {
  frictionlessCheckoutEmailSelector,
  frictionlessCheckoutPhoneSelector,
  isFrictionlessCheckoutEmailValidSelector,
  isFrictionlessCheckoutPhoneValidSelector,
} from 'store/auth/selectors';
import { authActions } from 'store/auth/slice';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';
import { useSelector } from 'store/utils';
import { colors, fontSize, breakpoints } from 'styles/cp';

const FrictionlessDetails: React.FC = () => {
  const dispatch = useDispatch();
  const emailFromSale = useSelector((state: RootState) =>
    saleSelectors.toEmailsSelector(state.sale)
  )?.[0];
  const frictionlessCheckoutEmail = useSelector(frictionlessCheckoutEmailSelector);
  const frictionlessCheckoutPhone = useSelector(frictionlessCheckoutPhoneSelector);
  const email = frictionlessCheckoutEmail || '';
  const phone = frictionlessCheckoutPhone || '+1 ';

  const isEmailValid = useDebounceSelector(isFrictionlessCheckoutEmailValidSelector);
  const isPhoneValid = useDebounceSelector(isFrictionlessCheckoutPhoneValidSelector);
  const { blurredFields, handleBlur, handleChange } = useFormHandlers();

  useEffect(() => {
    if (emailFromSale) {
      dispatch(authActions.setFrictionlessCheckoutEmail({ email: emailFromSale }));
    }
  }, [emailFromSale]);

  useEffect(() => {
    dispatch(
      authActions.setFrictionlessCheckoutSelected({
        frictionlessCheckoutSelected: isEmailValid && isPhoneValid,
      })
    );
  }, [isEmailValid, isPhoneValid, dispatch]);

  return (
    <React.Fragment>
      <div className="container">
        <div className="header">
          <span className="title">
            <FormattedMessage id="FRICTIONLESS_DETAILS" defaultMessage="Your details" />
          </span>
          <div className="details-tooltip">
            <Tooltip
              title={
                <FormattedMessage
                  id="FRICTIONLESS_DETAILS_TOOLTIP"
                  defaultMessage="Your email and phone is used to verify if you have an Intuit account to offer you a faster checkout"
                />
              }
              tooltipId="details-tooltip"
            >
              <InfoCircle color={colors.gray02} width={14} height={14} />
            </Tooltip>
          </div>
        </div>
        <div>
          <LabeledInputWithErrors
            label={<FormattedMessage id="FRICTIONLESS_EMAIL" defaultMessage="Email" />}
            type="email"
            name="email"
            value={email}
            error={blurredFields.email && !isEmailValid ? 'Invalid email' : ''}
            onChange={(e) => handleChange(e.target.value, 'email')}
            onBlur={() => handleBlur('email')}
            placeholder="Enter your email address"
          />
        </div>
        <div>
          <LabeledInputWithErrors
            label={<FormattedMessage id="FRICTIONLESS_PHONE" defaultMessage="Phone number" />}
            type="phone"
            name="phone"
            value={phone}
            error={blurredFields.phone && !isPhoneValid ? 'Invalid phone number' : ''}
            onChange={(e) => handleChange(e.target.value, 'phone')}
            onBlur={() => handleBlur('phone')}
            placeholder="+1 (555) 555-5555"
          />
        </div>
        <div>
          <span className="title change-payment-method">
            <FormattedMessage
              id="FRICTIONLESS_CHANGE_PAYMENT_METHOD"
              defaultMessage="CHANGE PAYMENT METHOD"
            />
          </span>
        </div>
      </div>
      {/*language=SCSS*/}
      <style jsx>{`
        .container {
          display: grid;
          grid-template-rows: 20px auto auto;
          grid-row-gap: 20px;

          .header {
            display: flex;
            align-items: center;
          }

          .title {
            font-family: AvenirNextforINTUIT-Demi;
            text-transform: uppercase;
            color: ${colors.gray02};
            font-size: ${fontSize.xxs};
            &.change-payment-method {
              @media (max-width: ${breakpoints.md}) {
                display: none;
              }
            }
          }

          .details-tooltip {
            margin: 2px 6px 0;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { FrictionlessDetails };
