import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ScrollingForm, {
  InjectedScrollingFormProps,
} from 'components/Core/Payment/PaymentForm/ScrollingForm/ScrollingForm';
import { IBankAccountCreateData } from 'components/Core/WalletForms/network/src/types';
import { BankField, FieldName } from 'components/Core/WalletForms/src/CreateBankTokenForm';
import { EnhancedFormikProps } from 'components/Core/WalletForms/src/Form';
import { BankAccountTypeEnumInput } from 'components/Core/WalletForms/validations/src/types';
import { AccountNumberInfo, QuestionMark, RoutingNumberInfo } from 'components/Shared/Icons/Icons';
import LabeledSelect from 'components/Shared/Inputs/LabeledSelect';
import ValidatedLabeledInput from 'components/Shared/Inputs/ValidatedLabeledInput';
import CpPopover from 'components/Shared/Popover/CpPopover';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import { formErrorTranslator } from 'store/wallet/errorTranslators';
import { breakpoints } from 'styles/cp';

type ACHFormProps = {
  bankDraftEnumErrors: EnhancedFormikProps<IBankAccountCreateData>['enumErrors'];
  bankDraftTouched: EnhancedFormikProps<IBankAccountCreateData>['touched'];
  onFieldBlur: (_field: {
    name: string;
    onBlur: Function;
  }) => (e: React.FocusEvent<HTMLInputElement>) => void;
  onBankFieldChange: (_field: {
    name: string;
    onChange: Function;
    value: string;
  }) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBankFieldFocus: (
    _field: { name: string; onFocus?: Function },
    fieldRef: React.RefObject<HTMLElement>
  ) => (e: React.FocusEvent<HTMLInputElement>) => void;
  isSubmitting: boolean;
} & InjectedScrollingFormProps;

export const ACHForm = ({
  scrollToRef,
  onFieldBlur,
  onBankFieldChange,
  onBankFieldFocus,
  isSubmitting,
  bankDraftEnumErrors,
  bankDraftTouched,
}: ACHFormProps) => {
  const [isAccountNumberTooltipOpen, setIsAccountNumberTooltipOpen] = useState(false);
  const [isRoutingNumberTooltipOpen, setIsRoutingNumberTooltipOpen] = useState(false);
  const bankCodeRef = useRef(null);
  const accountNumberRef = useRef(null);
  const nameRef = useRef(null);
  const { formatMessage } = useIntl();
  const errorTranslator = formErrorTranslator();

  const setTooltipState = (key: string, state: boolean) => {
    if (key === 'isAccountNumberTooltipOpen') {
      setIsAccountNumberTooltipOpen(state);
    }
    if (key === 'isRoutingNumberTooltipOpen') {
      setIsRoutingNumberTooltipOpen(state);
    }
  };

  const getTooltipStateFn = (accountOrRouting: string, state: boolean) => () =>
    setTooltipState(accountOrRouting, state);

  const toggle = (key: string) => {
    if (key === 'isAccountNumberTooltipOpen') {
      setIsAccountNumberTooltipOpen((prevValue) => !prevValue);
    }
    if (key === 'isRoutingNumberTooltipOpen') {
      setIsRoutingNumberTooltipOpen((prevValue) => !prevValue);
    }
  };

  const getToggleFn = (accountOrRouting: string) => () => toggle(accountOrRouting);

  const onTouchStart = (accountOrRouting: string) => () => {
    let popoverOpened;
    if (accountOrRouting === 'isAccountNumberTooltipOpen') {
      popoverOpened = !isAccountNumberTooltipOpen;
    }
    if (accountOrRouting === 'isRoutingNumberTooltipOpen') {
      popoverOpened = !isRoutingNumberTooltipOpen;
    }

    if (popoverOpened) {
      document.addEventListener('touchstart', getTooltipStateFn(accountOrRouting, false));
    } else {
      document.removeEventListener('touchstart', getTooltipStateFn(accountOrRouting, false));
    }
  };

  const scrollIfInError = ({ name }: { name: string }) => {
    const isInError =
      bankDraftTouched[name as FieldName] &&
      bankDraftEnumErrors[name as FieldName] &&
      typeof bankDraftEnumErrors[name as FieldName]![0] !== 'undefined';
    if (!isInError) {
      return null;
    }
    let refName: React.RefObject<HTMLElement>;
    switch (name) {
      case 'bankCode':
        refName = bankCodeRef;
        break;
      case 'accountNumber':
      case 'accountNumberConfirm':
        refName = accountNumberRef;
        break;
      case 'name':
        refName = nameRef;
        break;
      default:
        refName = React.createRef<HTMLElement>();
    }
    return scrollToRef && refName && scrollToRef(refName, false);
  };
  const isMobile = useIsMobileScreen(768);

  return (
    <React.Fragment>
      <BankField name="accountType">
        {({ field }) => (
          <div className="bank-field">
            <LabeledSelect
              {...field}
              disabled={isSubmitting}
              onChange={onBankFieldChange(field)}
              label={
                <FormattedMessage id="PAYFLOW_BANK_ACCOUNT_TYPE" defaultMessage="Account type" />
              }
            >
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_PERSONAL_CHECKING"
                defaultMessage="Personal checking"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.PERSONAL_CHECKING}>{message}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_PERSONAL_SAVINGS"
                defaultMessage="Personal savings"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.PERSONAL_SAVINGS}>{message}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_BUSINESS_CHECKING"
                defaultMessage="Business Checking"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.BUSINESS_CHECKING}>{message}</option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id="PAYFLOW_ACCOUNT_TYPE_BUSINESS_SAVINGS"
                defaultMessage="Business savings"
              >
                {(message) => (
                  <option value={BankAccountTypeEnumInput.BUSINESS_SAVINGS}>{message}</option>
                )}
              </FormattedMessage>
            </LabeledSelect>
          </div>
        )}
      </BankField>
      <BankField name="bankCode">
        {({ field }) => {
          return (
            <div ref={bankCodeRef} className="bank-field">
              <>
                <ValidatedLabeledInput
                  {...field}
                  translator={errorTranslator}
                  disabled={isSubmitting}
                  inputMode="decimal"
                  pattern="[0-9]*"
                  type="text"
                  maxLength="9"
                  dir="ltr"
                  onBlur={onFieldBlur(field)}
                  onChange={onBankFieldChange(field)}
                  onFocus={onBankFieldFocus(field, bankCodeRef)}
                  className="flex-any"
                  label={
                    <FormattedMessage
                      id="PAYFLOW_BANK_ROUTING_NUMBER"
                      defaultMessage="Routing number"
                    />
                  }
                  placeholder={formatMessage({
                    id: 'PAYFLOW_BANK_ROUTING_NUMBER',
                    defaultMessage: 'Routing number',
                  })}
                  validationError={
                    bankDraftTouched[field.name as 'bankCode'] &&
                    bankDraftEnumErrors[field.name as 'bankCode'] &&
                    bankDraftEnumErrors[field.name as 'bankCode']![0]
                  }
                >
                  <div
                    id="routingNumberQuestionMark"
                    className="tooltip-target-wrapper"
                    onTouchStart={onTouchStart('isRoutingNumberTooltipOpen')}
                  >
                    <QuestionMark width={18} height={18} />
                  </div>
                  <CpPopover
                    className="cp-tooltip-wrapper"
                    key={`cp-popover-${isMobile ? 'click' : 'hover'}`}
                    trigger={isMobile ? 'click' : 'hover'}
                    toggle={getToggleFn('isRoutingNumberTooltipOpen')}
                    innerClassName="cp-tooltip"
                    placement="top-end"
                    autoHide={false}
                    isOpen={isRoutingNumberTooltipOpen}
                    target="routingNumberQuestionMark"
                  >
                    <RoutingNumberInfo />
                  </CpPopover>
                </ValidatedLabeledInput>
                {scrollIfInError(field)}
              </>
            </div>
          );
        }}
      </BankField>
      <div ref={accountNumberRef} className="flex an-w">
        <BankField name="accountNumber">
          {({ field }) => (
            <div className="bank-field">
              <>
                <ValidatedLabeledInput
                  {...field}
                  translator={errorTranslator}
                  disabled={isSubmitting}
                  inputMode="decimal"
                  pattern="[0-9]*"
                  type="text"
                  maxLength="17"
                  dir="ltr"
                  onBlur={onFieldBlur(field)}
                  onChange={onBankFieldChange(field)}
                  onFocus={onBankFieldFocus(field, accountNumberRef)}
                  className="flex-any"
                  label={
                    <FormattedMessage
                      id="PAYFLOW_BANK_ACCOUNT_NUMBER"
                      defaultMessage="Account number"
                    />
                  }
                  placeholder={formatMessage({
                    id: 'PAYFLOW_BANK_ACCOUNT_NUMBER',
                    defaultMessage: 'Account number',
                  })}
                  validationError={
                    bankDraftTouched[field.name as 'accountNumber'] &&
                    bankDraftEnumErrors[field.name as 'accountNumber'] &&
                    bankDraftEnumErrors[field.name as 'accountNumber']![0]
                  }
                >
                  <div
                    id="accountNumberQuestionMark"
                    className="tooltip-target-wrapper"
                    onTouchStart={onTouchStart('isAccountNumberTooltipOpen')}
                  >
                    <QuestionMark width={18} height={18} />
                  </div>
                  <CpPopover
                    className="cp-tooltip-wrapper"
                    key={`cp-popover-${isMobile ? 'click' : 'hover'}`}
                    trigger={isMobile ? 'click' : 'hover'}
                    toggle={getToggleFn('isAccountNumberTooltipOpen')}
                    innerClassName="cp-tooltip"
                    placement="top-end"
                    autoHide={false}
                    isOpen={isAccountNumberTooltipOpen}
                    target="accountNumberQuestionMark"
                  >
                    <AccountNumberInfo />
                  </CpPopover>
                </ValidatedLabeledInput>
                {scrollIfInError(field)}
              </>
            </div>
          )}
        </BankField>
        <BankField name="accountNumberConfirm">
          {({ field }) => (
            <div className="bank-field">
              <ValidatedLabeledInput
                {...field}
                translator={errorTranslator}
                disabled={isSubmitting}
                inputMode="decimal"
                pattern="[0-9]*"
                type="text"
                maxLength="17"
                dir="ltr"
                onBlur={onFieldBlur(field)}
                onChange={onBankFieldChange(field)}
                onFocus={onBankFieldFocus(field, accountNumberRef)}
                className="flex-any"
                onPaste={(e: React.ClipboardEvent) => {
                  e.preventDefault();
                }}
                autoComplete="off"
                label={
                  <FormattedMessage
                    id="PAYFLOW_BANK_ACCOUNT_NUMBER_CONFIRM"
                    defaultMessage="Confirm account number"
                  />
                }
                placeholder={formatMessage({
                  id: 'PAYFLOW_BANK_ACCOUNT_NUMBER_CONFIRM',
                  defaultMessage: 'Confirm account number',
                })}
                validationError={
                  bankDraftTouched[field.name as 'accountNumberConfirm'] &&
                  bankDraftEnumErrors[field.name as 'accountNumberConfirm'] &&
                  bankDraftEnumErrors[field.name as 'accountNumberConfirm']![0]
                }
              />
            </div>
          )}
        </BankField>
      </div>

      <BankField name="name">
        {({ field }) => (
          <div ref={nameRef} className="bank-field">
            <>
              <ValidatedLabeledInput
                {...field}
                translator={errorTranslator}
                disabled={isSubmitting}
                onBlur={onFieldBlur(field)}
                onChange={onBankFieldChange(field)}
                onFocus={onBankFieldFocus(field, nameRef)}
                maxLength="64"
                label={
                  <FormattedMessage
                    id="PAYFLOW_BANK_ACCOUNT_NAME"
                    defaultMessage="Account holder's name"
                  />
                }
                validationError={
                  bankDraftTouched[field.name as 'name'] &&
                  bankDraftEnumErrors[field.name as 'name'] &&
                  bankDraftEnumErrors[field.name as 'name']![0]
                }
              />
              {scrollIfInError(field)}
            </>
          </div>
        )}
      </BankField>

      <style jsx>{`
        .bank-field {
          width: 100%;
          margin-bottom: 12px;

          .tooltip-target-wrapper {
            margin: 8px;
            height: 18px;
          }
        }

        .an-w {
          width: 100%;
          @media screen and (max-width: ${breakpoints.sm}) {
            display: block;
          }

          .bank-field {
            margin-right: 19px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default ScrollingForm(ACHForm);
