import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { authActions } from 'store/auth/slice';

export interface BlurredFields {
  email: boolean;
  phone: boolean;
}

export function useFormHandlers() {
  const dispatch = useDispatch();
  const [blurredFields, setBlurredFields] = useState<BlurredFields>({ email: false, phone: false });

  const handleBlur = useCallback((type: keyof BlurredFields) => {
    setBlurredFields((prev) => ({ ...prev, [type]: true }));
  }, []);

  const handleChange = useCallback(
    (value: string, type: keyof BlurredFields) => {
      if (type === 'email') {
        dispatch(authActions.setFrictionlessCheckoutEmail({ email: value }));
      } else if (type === 'phone') {
        dispatch(authActions.setFrictionlessCheckoutPhone({ phone: value }));
      }
    },
    [dispatch]
  );

  return {
    blurredFields,
    handleBlur,
    handleChange,
  };
}
