import React, { Fragment } from 'react';

import PaymentMethodBugs from 'components/Core/Payment/PaymentMethod/PaymentMethodBugs/PaymentMethodBugs';
import SavePaymentMethodCTA from 'components/Core/Payment/PaymentMethod/SavePaymentMethod/SavePaymentMethodCTA/SavePaymentMethodCTA';
import { FrictionlessCheckout } from 'components/Shared/FrictionlessCheckout/FrictionlessCheckout';
import { breakpoints } from 'styles/cp';

interface PaymentFooterProps {
  shouldShowSavePaymentMethodCta: boolean;
  isAddingCard: boolean;
  enabledPaymentMethods: string[];
  enabledCCTypes: string[];
  cdn: string;
  isFrictionlessCheckoutEnabled: boolean;
}
const PaymentFooter: React.FC<PaymentFooterProps> = ({
  shouldShowSavePaymentMethodCta,
  isAddingCard,
  enabledPaymentMethods,
  enabledCCTypes,
  cdn,
  isFrictionlessCheckoutEnabled,
}) => {
  if (isFrictionlessCheckoutEnabled) {
    return <FrictionlessCheckout />;
  }

  return (
    <Fragment>
      <div className="payment-method-footer">
        {shouldShowSavePaymentMethodCta ? (
          <div className="save-payment-method">
            <SavePaymentMethodCTA />
          </div>
        ) : (
          <div />
        )}
        {isAddingCard && (
          <PaymentMethodBugs
            enabledPaymentMethods={enabledPaymentMethods}
            enabledCCTypes={enabledCCTypes}
            cdn={cdn}
          />
        )}
      </div>
      <style jsx>{`
        .payment-method-footer {
          padding: 8px 0 24px;
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: ${breakpoints.md}) {
            flex-direction: column;
            align-items: center;
            gap: 8px;
          }

          .save-payment-method > div {
            display: inline-flex;
          }
        }
      `}</style>
    </Fragment>
  );
};

export { PaymentFooter };
