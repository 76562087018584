import React, { useEffect, useRef } from 'react';

import { renderAppFabricWidget } from './appfabricDevelopmentKit';

import { useSelector } from 'store/utils';

export interface FrictionlessLoginElevateOtpProps {
  onComplete: () => void;
  onError: (error: Error) => void;
  onOtpCancel: () => void;
}

/**
 * Steps to reproduce
 * 1. Open Frictionless Login enabled link
 * 2. Wait for the OTP widget
 * 3. Close the page/refresh without enetering OTP
 * 4. You are already logged in, but your cookie does not have enough permissions to fetch wallets
 * 5. This widget should be used to render OTP widget and elevate the permissions
 */
export const FrictionlessLoginElevateOtp: React.FC<FrictionlessLoginElevateOtpProps> = ({
  onComplete,
  onError,
  onOtpCancel,
}) => {
  const APPFABRIC_DEVELPMENT_KIT_SRC = useSelector(
    (state) => state.config.endpoints.appfabricDevelopmentKitJsSdk
  );
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    renderAppFabricWidget(
      APPFABRIC_DEVELPMENT_KIT_SRC,
      'cpclient-webplugin/frictionless-login',
      containerRef.current!,
      {
        isRemediationOnly: true,
        onDone: onComplete,
        onError,
        onCancel: onOtpCancel,
      }
    );
  }, []);
  return <div data-testid="frictionless-login-elevation-container" ref={containerRef} />;
};
