// this entire file should be removed once the business no longer requires it

import { useCallback } from 'react';

import { frictionlessLoginSessionIdSelector } from '../../../store/auth/selectors';
import { insightSelectors } from '../../../store/insight/selectors';
import { useSelector } from '../../../store/utils';

import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { ContextualParams } from 'types/SplunkLogs';

const splunkReporter = SplunkReporter.getInstance();

const logger = 'frictionlessLogin';

export interface FrictionlessLog {
  message: string;
  extraParams?: Record<string, string | boolean | number>;
  event?: ContextualParams['event'];
  logLevel?: ContextualParams['logInfo']['logLevel'];
  rum?: ContextualParams['rum'];
  error?: Error;
}

// in an external function as we may want the option to call this directly
const logFrictionlessEvent = ({
  token,
  logLevel = 'info',
  event = 'report',
  error,
  extraParams,
  sessionId,
  message,
  rum,
}: FrictionlessLog & { token: string; sessionId?: string }) => {
  splunkReporter.contextual({
    action: 'frictionlessLogin',
    activityInfo: {
      token,
      message,
      frictionlessSessionId: sessionId,
      ...extraParams,
    },
    event,
    logInfo: {
      logLevel,
      logType: 'contextual',
      logger,
    },
    error,
    rum,
  });
};

export const useReportFrictionlessEvent = () => {
  const token = useSelector((state) => insightSelectors.tokenSelector(state.insight));
  const sessionId = useSelector(frictionlessLoginSessionIdSelector);

  const log = useCallback(
    (params: FrictionlessLog) => {
      logFrictionlessEvent({
        ...params,
        token,
        sessionId,
      });
    },
    [token, sessionId]
  );

  return {
    log,
  };
};
