import React from 'react';

import ErrorAlertIcon from 'components/Shared/Icons/ErrorAlertIcon';
import LabeledInput from 'components/Shared/Inputs/LabeledInput';
import { colors } from 'styles/cp';

type InputWithErrorProps = {
  label: React.ReactNode;
  type: string;
  name: string;
  value: string;
  error: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  placeholder?: string;
};

const LabeledInputWithErrors: React.FC<InputWithErrorProps> = ({
  label,
  type,
  name,
  value,
  error,
  onChange,
  onBlur,
  placeholder,
}) => {
  return (
    <div>
      <LabeledInput
        label={label}
        type={type}
        name={name}
        value={value}
        isInError={!!error}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {!!error && (
        <div className="error-notice">
          <span className="error-icon">
            <ErrorAlertIcon height={16} width={16} />
          </span>
          <span>{error}</span>
        </div>
      )}
      <style jsx>{`
        .error-notice {
          padding-top: 8px;
          color: ${colors.darkError};
          font-size: 12px;
          display: flex;
          align-content: center;

          .error-icon {
            padding-right: 4px;
          }
        }
      `}</style>
    </div>
  );
};

export default LabeledInputWithErrors;
