import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PreLoginSplashScreen from 'components/Core/Autopay-schedule/ScheduleAndAutoPay/PreLoginSplashScreen/PreLoginSplashScreen';
import AutopaySetup from 'components/Core/Autopay-schedule/Setup/AutopaySetup';
import Calendar from 'components/Shared/Icons/Calendar';
import NewLabel from 'components/Shared/Icons/NewLabel';
import Recurring from 'components/Shared/Icons/Recurring';
import DatePicker from 'components/Shared/Inputs/DatePicker';
import CpPopover from 'components/Shared/Popover/CpPopover';
import Separator from 'components/Shared/Separator/Separator';
import SegmentIO from 'reporting/SegmentIO';
import { signIn } from 'store/auth/slice';
import { modalActions } from 'store/modal/slice';
import { setScheduledDate, paymentActions } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { colors, breakpoints, fontSize } from 'styles/cp';
import { Modal } from 'types/Modal';
import { TXN_MAP } from 'types/constants';

const deviceInfo: { isIOSDevice: Function } = require('server/helpers/deviceInfo');

const validPaymentMethods = ['cc', 'dc', 'dc,cc', 'bank'];

export interface Props {
  dueDate?: string;
  balance?: number;
  recurringInfo?: any;
  auth: {
    entityId: string;
    isUserSignedIn: boolean;
  };
  insight: {
    shouldShowAutopay: boolean;
    shouldShowSchedulePay: boolean;
  };
  config: { endpoints: any };
  payment: {
    scheduledDate: string | Date;
    isAutoPayOn: boolean;
    amount: number;
    gratuityValue: number;
    paymentStatus: string;
    paymentMethodType: string;
  };
  ixp: any;
  // eslint-disable-next-line no-unused-vars
  setIsAutoPayOn: (value: boolean) => void;
  setScheduledDate: () => void;
  autopayStartDate: string | Date;
  // eslint-disable-next-line no-unused-vars
  showModal: (Component: Pick<Modal, 'component' | 'componentProps'>, args?: Object) => void;
  hideModal: () => void;
  isSubscriptionOnly: boolean;
  featureFlags: { [key: string]: any };
  signIn: () => void;
}

const ScheduleAndAutoPay: React.FC<Props> = ({
  dueDate,
  balance = 0,
  recurringInfo,
  auth: { isUserSignedIn },
  payment: {
    amount,
    gratuityValue = 0,
    scheduledDate = new Date(),
    isAutoPayOn = false,
    paymentStatus,
    paymentMethodType,
  },
  insight: { shouldShowAutopay = false, shouldShowSchedulePay = false },
  setScheduledDate,
  setIsAutoPayOn,
  autopayStartDate = new Date(),
  showModal,
  hideModal,
  isSubscriptionOnly,
  signIn,
  featureFlags,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const subscriptionsOnly = isSubscriptionOnly;
  if (!validPaymentMethods.includes(paymentMethodType) || subscriptionsOnly) {
    return null;
  }
  dueDate = typeof dueDate === 'string' ? dueDate.replace(/-/g, '/') : '';

  const isIos = deviceInfo.isIOSDevice(
    (typeof window === 'object' && window.navigator && window.navigator.userAgent) || ''
  );

  const openModal = () => {
    const activity_type = shouldShowAutopay ? 'auto_pay' : 'schedule_pay';

    SegmentIO.transactionEngaged({
      activity_type,
      ui_object_detail: `sign_in_${activity_type}`,
      ui_object: 'link',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });

    const onContinue = () => {
      SegmentIO.transactionEngaged({
        activity_type,
        ui_object_detail: `sign_in_to_set_up`,
        ui_object: 'button',
        ui_action: 'clicked',
        ui_access_point: 'modal',
      });
      signIn();
    };

    const onHideModal = () => {
      SegmentIO.transactionEngaged({
        activity_type,
        ui_object_detail: `exit_${activity_type}_popup`,
        ui_object: 'button',
        ui_action: 'clicked',
        ui_access_point: 'modal',
      });
      hideModal();
    };

    const ModalContent = () => (
      <PreLoginSplashScreen
        isAutoPay={shouldShowAutopay}
        closeCallback={onHideModal}
        loginCallback={onContinue}
      />
    );
    showModal({
      component: ModalContent,
      componentProps: {
        closeOnClickOutSide: true,
        closeOnEsc: true,
        showCloseButton: false,
      },
    });
  };

  const onAutopayChange = () => {
    const isOn = !isAutoPayOn;
    SegmentIO.transactionEngaged({
      ui_object: 'checkbox',
      ui_object_detail: isOn ? 'turn_on_autopay' : 'turn_off_autopay',
      ui_action: 'enabled',
      ui_access_point: 'transaction_flow',
    });
    setIsAutoPayOn(isOn);
  };

  const isFullAmountPaid = () => {
    let isDisabled = false;
    try {
      const totalAmount = isNaN(amount) ? 0 : amount;
      const amountWithoutTip = Number((totalAmount - gratuityValue).toFixed(2));
      isDisabled = amountWithoutTip !== balance;
    } catch (error: any) {
      isDisabled = false;
    }
    return isDisabled;
  };

  const hasGratuityValue = gratuityValue > 0;
  const isDisabled = hasGratuityValue || isFullAmountPaid();
  const isPaymentInProgress = paymentStatus === TXN_MAP.STATUS.IN_PROGRESS;

  let autoPayInterval;
  if (recurringInfo && typeof recurringInfo.recurringDescription === 'string') {
    autoPayInterval = recurringInfo.recurringDescription.toLowerCase();
  }

  const setADateElement = (
    <Fragment>
      <div className="action" onClick={openModal} data-cy="schedule-and-auto-pay-link-set-a-date">
        {shouldShowSchedulePay ? (
          <FormattedMessage id="SET_A_DATE" defaultMessage="Set a date" />
        ) : (
          <FormattedMessage id="SET_UP_AUTOPAY" defaultMessage="Set up autopay" />
        )}
      </div>

      <style jsx>{`
        .action {
          font-family: AvenirNextforINTUIT-Regular;
          color: ${colors.blue};
          cursor: pointer;

          @media screen and (max-width: ${breakpoints.sm}) {
            font-size: 14px;
          }
        }
      `}</style>
    </Fragment>
  );

  const getTooltipMsg = () => {
    const msgDatePickerDisabled = (
      <FormattedMessage
        id="DATE_PICKER_DISABLED_TOOL_TIP"
        description="disabled date picker tool tip"
        defaultMessage="You have to pay in full if you schedule a payment date other than today. You can’t change the amount."
      />
    );
    const msgHasGratuityValue = (
      <FormattedMessage
        id="DATE_PICKER_DISABLED_TOOL_TIP_GRATUITY"
        description="disabled date picker tool tip"
        defaultMessage="Schedule payments don't support tips at the moment. You can clear the tip value and then set a schedule payment"
      />
    );

    return hasGratuityValue ? msgHasGratuityValue : msgDatePickerDisabled;
  };

  if (featureFlags['SBSEG-CP-block-autopay-schedulepay']) {
    return null;
  }

  if (!shouldShowSchedulePay && !shouldShowAutopay) {
    return null;
  }

  return (
    <Fragment>
      <div className="container" data-cy="schedule-and-autopay-component">
        <div className="header">
          {shouldShowSchedulePay ? (
            <FormattedMessage id="PAYMENT_DATE" defaultMessage="Payment date" />
          ) : (
            <FormattedMessage id="AUTOPAY" defaultMessage="Autopay" />
          )}
        </div>
        {!isUserSignedIn && <div className="set-a-date-mobile">{setADateElement}</div>}
        {(shouldShowSchedulePay || shouldShowAutopay) && (
          <div className="new-label">
            <NewLabel width={40} height={16} fontSize={12} />
          </div>
        )}
        {isUserSignedIn ? (
          <div className="user">
            <CpPopover
              className="cp-tooltip-wrapper"
              innerClassName="cp-tooltip"
              placement="top-start"
              isOpen={isDisabled && isTooltipOpen}
              target="payment-action"
            >
              {getTooltipMsg()}
            </CpPopover>
            <div
              id="payment-action"
              style={{ display: 'inline-block' }}
              onClick={() => isIos && setIsTooltipOpen(!isTooltipOpen)}
              onMouseEnter={() => !isIos && setIsTooltipOpen(true)}
              onTouchStart={() => !isIos && setIsTooltipOpen(!isTooltipOpen)}
              onMouseLeave={() => setIsTooltipOpen(false)}
            >
              {shouldShowSchedulePay ? (
                <DatePicker
                  dueDate={dueDate}
                  scheduledDate={scheduledDate}
                  onChange={setScheduledDate}
                  isDisabled={isDisabled || isPaymentInProgress}
                />
              ) : (
                <AutopaySetup
                  onChange={onAutopayChange}
                  isAutoPayOn={isAutoPayOn}
                  startDate={autopayStartDate}
                  interval={autoPayInterval}
                  disabled={isDisabled || isPaymentInProgress}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="guest">
            <div className="description-icon">
              {shouldShowSchedulePay && <Calendar width={23.33} height={24} />}
              {shouldShowAutopay && <Recurring width={24} height={24} />}
            </div>
            <div className="description">
              {shouldShowSchedulePay ? (
                <FormattedMessage
                  id="SCHEDULE_NOW_PAY_LATER"
                  defaultMessage="Schedule it now, pay it on the date you choose."
                />
              ) : (
                <FormattedMessage
                  id="AUTOPAY_DESCRIPTION"
                  defaultMessage="Pay this invoice on time, every time."
                />
              )}
            </div>
            <div className="set-a-date-desktop">{setADateElement}</div>
          </div>
        )}
      </div>
      <Separator height={12} />

      <style jsx>{`
        .container {
          display: initial;
          flex-direction: unset;
          margin-bottom: 16px;
          align-items: ${isUserSignedIn ? 'unset' : 'center'};
          @media screen and (max-width: ${breakpoints.sm}) {
            padding-bottom: 8px;
          }

          .header {
            display: inline-block;
            font-family: AvenirNextforINTUIT-Demi;
            font-size: 14px;
            margin: 0px 8px 0px 0px;
            color: ${colors.gray};

            @media screen and (max-width: ${breakpoints.sm}) {
              font-size: 14px;
              padding-right: 4px;
            }
          }

          .set-a-date-mobile {
            display: none;
            @media screen and (max-width: ${breakpoints.sm}) {
              display: inline-flex;
            }
          }

          .new-label {
            display: inline-block;
            margin-left: 8px;
          }

          .guest {
            display: flex;
            align-items: center;
            font-size: ${fontSize.xs};

            color: ${colors.gray03};
            @media screen and (max-width: ${breakpoints.sm}) {
              padding-bottom: 4px;
            }

            .description-icon {
              padding-right: 3px;
              margin-top: 5px;
            }

            .description {
              padding-right: 5px;

              font-size: ${fontSize.xs};
            }

            .set-a-date-desktop {
              @media screen and (max-width: ${breakpoints.sm}) {
                display: none;
              }
            }
          }

          .user {
            display: block;
            padding-top: 4px;
          }
        }
      `}</style>
    </Fragment>
  );
};

function mapStateToProps(store: any): any {
  const { sale, featureFlags, payment, auth, config, insight, ixp } = store;
  return {
    dueDate: saleSelectors.dueDateSelector(sale),
    balance: saleSelectors.balanceSelector(sale),
    recurringInfo: saleSelectors.getRecurringInfo(sale),
    isSubscriptionOnly: saleSelectors.isSubscription(sale),
    featureFlags,
    auth,
    ixp,
    config,
    payment,
    insight,
  };
}

export { ScheduleAndAutoPay };

export default injectIntl(
  // @ts-ignore
  connect(mapStateToProps, {
    setScheduledDate,
    setIsAutoPayOn: paymentActions.setIsAutoPayOn,
    showModal: modalActions.show,
    hideModal: modalActions.hide,
    signIn,
  })(ScheduleAndAutoPay)
);
