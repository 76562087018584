import React, { FC, Suspense } from 'react';

interface SSrSuspenseProps {
  fallback: React.ReactElement;
  children?: React.ReactElement;
}

const SSRSuspense: FC<SSrSuspenseProps> = ({ fallback, children }) => {
  const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
  return isServer ? fallback : <Suspense fallback={fallback}>{children}</Suspense>;
};

export default SSRSuspense;
