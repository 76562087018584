/* eslint-disable */
import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const nameValidator: WalletValidatorFunction = (
  name: any,
) => {
  const errors: ValidationError[] = [];
  if (typeof name !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {

    const nameCommaRegex = /^[\w]+\,[ ]*[\w]+.*$/,
      nameSpaceRegex = /^[\w]+[ ]+[\w]+.*$/,
      regexCheck = !(nameCommaRegex.test(name) || nameSpaceRegex.test(name));

    if (regexCheck) {
      errors.push(ValidationErrorEnum.INVALID_FIRST_AND_LAST_NAME);
    }
  }

  return [errors.length === 0, errors];
};