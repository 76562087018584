import React from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { CheckoutPortalFormattedMessage } from 'components/Core/CheckoutPrtalForamattedMessage/CheckoutPortalFormattedMessage';
import LabeledCheckbox from 'components/Shared/Inputs/LabeledCheckbox';
import { getFormattedAmountWithoutTax, getIntervalSentence } from 'components/Subscription/utils';
import SegmentIO from 'reporting/SegmentIO';
import { getLegalLinks } from 'shared/clientUtils';
import { formatDateMMDDYYWithoutZeros } from 'shared/dates';
import { lowercaseFirstLetter } from 'shared/utils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { PAYMENT_REJECT_TYPES } from 'store/payment/helpers';
import { setIsSavePaymentMethodChecked } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';
import { colors, fontSize } from 'styles/cp';

export interface SubscriptionConsentProps {
  startDate: string;
  startDateInstant: string;
  endDateInstant?: string;
  companyName: string;
  amount: number;
  currency: string;
  isSavePaymentMethodChecked: boolean;
  paymentRejectType: string;
  setIsSavePaymentMethodChecked: (isSavePaymentMethodChecked: boolean) => void;
  taxAmount: number | null;
  frequency?: string;
  frequencyInterval?: number;
  daysOfWeek?: string[];
  monthIndex?: number;
  daysOfMonth?: number[];
  weekIndex?: number;
  totalAmountWithoutOTF: number;
  oneTimeFeeEnabled: boolean;
}

const SubscriptionConsent: React.FC<SubscriptionConsentProps> = ({
  companyName,
  amount,
  startDate,
  startDateInstant,
  endDateInstant,
  currency,
  isSavePaymentMethodChecked,
  paymentRejectType,
  setIsSavePaymentMethodChecked,
  taxAmount,
  frequency,
  frequencyInterval,
  daysOfWeek,
  monthIndex,
  daysOfMonth,
  weekIndex,
  totalAmountWithoutOTF,
  oneTimeFeeEnabled,
}) => {
  const onChange = () => {
    const newValue = !isSavePaymentMethodChecked;
    SegmentIO.transactionEngaged({
      ui_object: 'checkbox',
      ui_object_detail: 'terms',
      ui_action: newValue ? 'enabled' : 'disabled',
      ui_access_point: 'transaction_flow',
    });
    setIsSavePaymentMethodChecked(newValue);
  };

  const style = {
    fontSize: fontSize.xxs,
    color: colors.darkGray,
  };

  const intl = useIntl();
  const { privacyUrl, tosUrl } = getLegalLinks(intl.locale);

  const consentDefaultMessageP1 = `<b>By selecting Agree and pay, I authorize {companyName} to charge the payment method listed above ${
    oneTimeFeeEnabled ? ' {pretaxAmount}, plus tax,' : ''
  }</b>`;

  const consentDefaultMessageP2 = `<b> on {startDate} and {interval} thereafter at {pretaxAmount}, plus tax, ${
    endDateInstant ? `until {endDate}` : ''
  } unless I cancel. I can cancel anytime by clicking on the manage recurring payment link in the emails.</b> I agree to the <tosLink>Terms of Service</tosLink> and that I've read and acknowledged Intuit's <privacyLink>Global Privacy Statement</privacyLink>. You also provide written instructions for Intuit Inc. to obtain and periodically refresh information from bank verification data providers to validate your bank account information and for other fraud detection purposes.`;

  const pretaxAmount = getFormattedAmountWithoutTax(amount, currency, taxAmount);

  return (
    <div className="subscription-consent-cta">
      <LabeledCheckbox
        id="cta"
        checked={isSavePaymentMethodChecked}
        onChange={onChange}
        error={paymentRejectType === PAYMENT_REJECT_TYPES.MISSING_CONSENT}
        label={
          <span style={style} id="cta-description">
            <CheckoutPortalFormattedMessage
              id={
                oneTimeFeeEnabled
                  ? 'SUBSCRIPTION_CONSENT_V1_P1_WITH_OTF'
                  : 'SUBSCRIPTION_CONSENT_V1_P1'
              }
              defaultMessage={consentDefaultMessageP1}
              values={{
                companyName,
                pretaxAmount: (
                  <FormattedNumber style="currency" currency={currency} value={pretaxAmount} />
                ),
                b: (chunks: string) => <span className="bold-text">{chunks}</span>,
              }}
            />
            <CheckoutPortalFormattedMessage
              id={
                endDateInstant
                  ? 'SUBSCRIPTION_CONSENT_V1_P2_WITH_END_DATE'
                  : 'SUBSCRIPTION_CONSENT_V1_P2'
              }
              defaultMessage={consentDefaultMessageP2}
              values={{
                startDate: formatDateMMDDYYWithoutZeros(startDateInstant),
                interval: lowercaseFirstLetter(
                  getIntervalSentence({
                    frequency,
                    frequencyInterval,
                    daysOfWeek,
                    monthIndex,
                    daysOfMonth,
                    weekIndex,
                    startDate,
                  })
                ),
                endDate: endDateInstant ? formatDateMMDDYYWithoutZeros(endDateInstant) : '',
                pretaxAmount: (
                  <FormattedNumber
                    style="currency"
                    currency={currency}
                    value={oneTimeFeeEnabled ? totalAmountWithoutOTF : pretaxAmount}
                  />
                ),
                b: (chunks: string) => <span className="bold-text">{chunks}</span>,
                tosLink: (chunks: string) => (
                  <a className="link" href={tosUrl}>
                    {chunks}
                  </a>
                ),
                privacyLink: (chunks: string) => (
                  <a className="link" href={privacyUrl}>
                    {chunks}
                  </a>
                ),
              }}
            />
          </span>
        }
      />

      <style jsx>{`
        .subscription-consent-cta {
          padding: 16px 0;
        }

        .bold-text {
          font-family: AvenirNextforINTUIT-Demi;
        }

        .link {
          color: ${colors.blue05};
          text-decoration: none;
        }
      `}</style>
    </div>
  );
};

function mapStateToProps(store: RootState) {
  const { payment, sale, companyInfo } = store;
  const {
    startDate,
    startDateInstant,
    endDateInstant,
    frequency,
    frequencyInterval,
    daysOfWeek,
    monthIndex,
    daysOfMonth,
    weekIndex,
  } = saleSelectors.subscriptionInfo(sale) || {};

  return {
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    currency: saleSelectors.currencySelector(sale),
    amount: saleSelectors.amountSelector(sale),
    oneTimeFeeEnabled: saleSelectors.oneTimeFeeEnabled(sale),
    totalAmountWithoutOTF: saleSelectors.totalAmountWithoutOTF(sale, true),
    startDate,
    startDateInstant,
    endDateInstant,
    frequency,
    frequencyInterval,
    daysOfWeek,
    monthIndex,
    daysOfMonth,
    weekIndex,
    isSavePaymentMethodChecked: payment.isSavePaymentMethodChecked,
    paymentRejectType: payment.paymentRejectType,
    taxAmount: saleSelectors.taxAmountSelector(sale),
  };
}

export default connect(mapStateToProps, {
  setIsSavePaymentMethodChecked,
  // @ts-expect-error
  // type mismatch
})(SubscriptionConsent);

export { SubscriptionConsent };
