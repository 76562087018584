import React from 'react';

import FrictionlessLoginPartOne, {
  FrictionlessLoginPartOneHeader,
  FrictionlessLoginPartOneSubHeader,
} from './FrictionlessLoginPartOne';
import FrictionlessLoginPartTwo from './FrictionlessLoginPartTwo';

import BottomSheetModal from 'components/Core/Modal/BottomSheetModal';

export type frictionlessLoginState = 'step-1' | 'step-2' | 'cancelled';

export interface FrictionlessLoginProps {
  email: string;
  sessionId: string;
  stepState: frictionlessLoginState;
  onContinueButtonClick: () => void;
  onHide: (source: 'before_sure' | 'after_sure') => void;
  onNotMeButtonClick: () => void;
  onOtpCancel: () => void;
  onComplete: () => void;
  onError: (error: Error) => void;
  onReady: (startedAt: Date) => void;
  isGuestCheckoutAllowed: boolean;
}

const FrictionlessLogin: React.FC<FrictionlessLoginProps> = ({
  email,
  sessionId,
  stepState,
  onHide,
  onContinueButtonClick,
  onNotMeButtonClick,
  onComplete,
  onError,
  onReady,
  onOtpCancel,
  isGuestCheckoutAllowed,
}) => {
  const handleHide = () => {
    switch (stepState) {
      case 'step-1':
        return onHide('before_sure');
      case 'step-2':
        return onHide('after_sure');
      default:
        return;
    }
  };

  if (stepState === 'cancelled') {
    return null;
  }
  let modalBody: React.ReactNode | undefined,
    modalHeader: React.ReactNode | undefined,
    modalSubHeader: React.ReactNode | undefined;

  switch (stepState) {
    case 'step-1':
      modalBody = (
        <FrictionlessLoginPartOne
          onCancelButtonClick={handleHide}
          onContinueButtonClick={onContinueButtonClick}
          onNotMeButtonClick={onNotMeButtonClick}
          email={email}
          isGuestCheckoutAllowed={isGuestCheckoutAllowed}
        />
      );
      modalHeader = FrictionlessLoginPartOneHeader;
      modalSubHeader = FrictionlessLoginPartOneSubHeader;
      break;
    case 'step-2':
      modalBody = (
        <FrictionlessLoginPartTwo
          email={email}
          sessionId={sessionId}
          onCancelButtonClick={handleHide}
          onComplete={onComplete}
          onError={onError}
          onReady={onReady}
          onOtpCancel={onOtpCancel}
          isGuestCheckoutAllowed={isGuestCheckoutAllowed}
        />
      );
      break;
    default:
      throw new Error('Unrecognized step');
  }

  return (
    <BottomSheetModal
      hide={handleHide}
      header={modalHeader}
      subHeader={modalSubHeader}
      isCloseVisible={false}
    >
      {modalBody}
    </BottomSheetModal>
  );
};

export default FrictionlessLogin;
